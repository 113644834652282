/*@-ms-viewport {
  width: device-width;
}*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
thead {
  margin: 0;
  padding: 0;
  font-size: 100%;
  background: transparent;
  border: 0;
  outline: 0;
}
ol,
ul {
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  background: none;
  border: none;
  cursor: pointer;
}
input {
  border: none;
}
input[type="submit"] {
  border: none;
  cursor: pointer;
}
button {
  align-items: unset;
}
html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1.5;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  background-color: #010101;
}
@font-face {
  font-display: fallback;
  font-weight: normal;
  font-family: montserratextralight;
  font-style: normal;
  src: url("/fonts/montserrat-extralight-webfont.woff2") format("woff2"), url("/fonts/montserrat-extralight-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-weight: normal;
  font-family: montserratregular;
  font-style: normal;
  src: url("/fonts/montserrat-regular-webfont.woff2") format("woff2"), url("/fonts/montserrat-regular-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-weight: normal;
  font-family: montserratsemibold;
  font-style: normal;
  src: url("/fonts/montserrat-semibold-webfont.woff2") format("woff2"), url("/fonts/montserrat-semibold-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-weight: normal;
  font-family: dancingscriptregular;
  font-style: normal;
  src: url("/fonts/dancingscript-regular-webfont.woff2") format("woff2"), url("/fonts/dancingscript-regular-webfont.woff") format("woff");
}
body,
input,
textarea,
option,
select {
  color: #000000;
  font-size: 16px;
  font-family: montserratregular, arial, Helvetica, sans-serif;
  line-height: 1.6;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: montserratregular, arial, Helvetica, sans-serif;
}
a {
  color: #f59a00;
  text-decoration: none;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
  transition: color 0.4s ease-in-out;
}
a:hover,
a:focus,
a:active {
  color: #229660;
  text-decoration: underline;
}
p,
ul,
ol,
table,
blockquote {
  margin: 0;
  padding: 0;
}
h1,
.h1 {
  font-family: montserratextralight, arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 1.3;
  /* @media #{$large-start} {
    font-size: 24px;
    line-height: 28px;
  } */
}
h2,
.h2 {
  font-size: 24px;
  line-height: 1.3;
  /* @media #{$large-start} {
    font-size: 18px;
    line-height: 24px;
  } */
}
@media only screen and (min-width: 800px) {
  h2,
  .h2 {
    font-size: 30px;
  }
}
h3,
.h3 {
  font-size: 20px;
  line-height: 1.3;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
ul {
  list-style-type: square;
}
ul li,
ol li {
  color: #000;
}
.wrapper_main {
  position: relative;
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
}
.wrapper_1900 {
  position: relative;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
}
.sideoffset {
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (min-width: 1680px) {
  .sideoffset {
    padding-right: 0;
    padding-left: 0;
  }
}
.menu_column {
  width: 100%;
  margin-bottom: 20px;
}
@media only screen and (min-width: 900px) {
  .menu_column {
    flex: 0 0 300px;
    margin-bottom: 0;
  }
  .menu_column .title {
    padding: 10px 20px;
    color: #ffffff;
    font-size: 20px;
    background-color: #f59a00;
  }
}
.menu_column ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
@media only screen and (min-width: 900px) {
  .menu_column ul {
    display: block;
  }
}
.menu_column li {
  margin-right: 10px;
}
.menu_column li .link {
  display: block;
  padding: 5px 0;
}
.content_column {
  width: 100%;
}
@media only screen and (min-width: 900px) {
  .content_column {
    flex: 1;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 900px) {
  .menu_column__wrapper {
    border: 1px solid #f59a00;
  }
}
@media only screen and (min-width: 900px) {
  .content_column__list {
    padding: 20px;
  }
}
/*# sourceMappingURL=css/main.css.map */